import { ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { inject } from '@angular/core';

import { AuthService } from '@app/auth/auth.service';

export const homeGuard: CanActivateChildFn = (
  _: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | UrlTree => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  if (!authService.isAuthenticated()) {
    // We should only set redirect url if the user is not authenticated
    authService.logoutUser(state.url);

    return false;
  }

  if (!authService.hasCompletedOnboarding) {
    return router.parseUrl('/onboarding');
  }

  if (!authService.hasAccessibleBusinesses) {
    return router.parseUrl('/switch');
  }

  return true;
};
